export const services = [
    {
        sIcon: 'fi flaticon-lamp',
        title: 'Enterprise Application Development and Integrations',
        des: 'We develop and integrate robust enterprise applications that streamline operations.',
        link: '/service-single',
        
    },
    {
        sIcon: 'fi flaticon-edit',
        title: 'Custom Development & Integration',
        des: 'Our custom development services are designed to meet the unique needs of your business.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-startup',
        title: 'Customer Experience',
        des: 'Enhance your customer interactions and satisfaction with our customer experience solutions.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-business',
        title: 'BI & Analytics',
        des: 'Unlock the power of your data with our BI & Analytics services.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Managed Services',
        des: 'Our managed services ensure your IT infrastructure is reliable, secure, and efficient.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'IT Talent',
        des: 'Access top IT talent to meet your project and staffing needs.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Strategy',
        des: 'Our strategic consulting services help you navigate complex IT landscapes and achieve your business goals.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Veteran Services',
        des: 'We are committed to supporting veterans by providing specialized services.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Business Consulting',
        des: 'Our business consulting services help you optimize operations and achieve strategic objectives.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Cloud Operations',
        des: 'Leverage the power of the cloud with our cloud operations services.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Machine Learning and AI',
        des: 'Harness the power of artificial intelligence with our AI and ML services.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'UI/UX',
        des: 'Create intuitive and engaging user experiences with our UI/UX services.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Digital Transformation',
        des: 'Drive profound innovation and transformative impact with our digital transformation services.',
        link: '/service-single',
    },
    {
        sIcon: 'fi flaticon-trophy',
        title: 'Cloud Platforms',
        des: 'We excel across major cloud platforms, driving innovation and transformation.',
        link: '/service-single',
    },


]