import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo.jpg'

import ins1 from '../../images/instragram/1.jpg'
import ins2 from '../../images/instragram/2.jpg'
import ins3 from '../../images/instragram/3.jpg'
import ins4 from '../../images/instragram/4.jpg'
import ins5 from '../../images/instragram/5.jpg'
import ins6 from '../../images/instragram/6.jpg'
import { services } from '../../constants'


const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="wpo-site-footer">
        <div className="wpo-upper-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget about-widget">
                            <div className="logo widget-title">
                                <Link onClick={ClickHandler} to="/">
                                    <img src={Logo} alt="blog"/>
                                </Link>
                            </div>
                            <p>Management consulting includes a broad range of activities, and the many firms and their members often define these practices.</p>
                            <ul>
                                
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-twitter-alt"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link onClick={ClickHandler} to="/">
                                        <i className="ti-linkedin"></i>
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget wpo-service-link-widget">
                            <div className="widget-title">
                                <h3>Contact </h3>
                            </div>
                            <div className="contact-ft">
                                <ul>
                                    <li><i className="fi flaticon-location"></i>2-8-2 Main Road Waddepally Hanamkonda Telangana 506370</li>
                                    <li><i className="fi flaticon-phone-call"></i>+91 832 817 0647</li>
                                    <li><i className="fi flaticon-send"></i>contactus@acornsphere.com</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
                        <div className="widget link-widget">
                            <div className="widget-title">
                                <h3>Services </h3>
                            </div>
                            <ul>
                            {services.slice(0,4).map((service, sitem) => (
                                <li><Link onClick={ClickHandler} to={service.link}>{service.title}</Link></li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
        <div className="wpo-lower-footer">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <p className="copyright"> Copyright &copy; 2024 Acornsphere. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;