import React, {Fragment} from 'react';
import {Helmet} from "react-helmet";
import Navbar2 from '../../components/Navbar2'
import PageTitle from '../../components/pagetitle'
import Contactpage from '../../components/Contactpage'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const ContactPage =() => {
    return(
        <Fragment>
            <Helmet>
                <title>Acornsphere - Contact Us</title>
            </Helmet>
            <Navbar2/>
            <PageTitle pageTitle={'Contact Us'} pagesub={'Contact'} type={'contact'}/> 
            <Contactpage/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ContactPage;

